import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';

import ContentListComponent from './components/ContentListComponent';
import {ContentService} from './services/ContentService';
import Login from './components/Login';


import './App.css';
import {Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";


export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.contentService = new ContentService('/h5p');
        this.parentUrl = process.env.REACT_APP_PARENT_URL ?? '';
    }
    private contentService: ContentService;
    private parentUrl: string;
    public state: {
        loggedIn: boolean;
        contentId: string;
        playing: boolean;
        zzz: string;
        role: number;
    } = {
        loggedIn: false,
        contentId: '-',
        playing: false,
        zzz: 'editor',
        role: 0
    };
    public async componentDidMount(): Promise<void> {
        window.addEventListener('message', this.receiveMessage, false);
        const payload = {loaded: true, contentHeight: window.outerHeight}; // Thay thế bằng tham số thực tế của bạn
        window.parent.postMessage({type: 'callParentFunction', payload}, this.parentUrl);

        let frameHeight = 0;
        const interval = setInterval(() => {
            let h5pIframe = document.getElementsByTagName('iframe')[0];
            if (h5pIframe && frameHeight != h5pIframe.clientHeight) {
                const payload = {contentHeight: h5pIframe.clientHeight};
                window.parent.postMessage({type: 'callParentFunction', payload}, this.parentUrl);
                frameHeight = h5pIframe.clientHeight;
                console.log(h5pIframe.clientHeight);
            }
        }, 500);
    }

    public componentWillUnmount() {
        window.removeEventListener('message', this.receiveMessage, false);
    }

    private login = (username, type) => {
        fetch('/login', {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password:  username.includes('student') ? 'student' : 'admin'
            })
        })
            .then(async (res) => {
                if (res.status === 200) {
                    const loginData = await res.json();
                    //console.log(loginData);
                    this.setState({
                        ...this.state,
                        loginData,
                        loginMessage: undefined
                    });
                    if (loginData.csrfToken) {
                        this.contentService.setCsrfToken(
                            loginData.csrfToken
                        );
                        const payload = {key: 'csrfToken' + type, value: loginData.csrfToken};
                        console.log('----------post login token', loginData.csrfToken);
                        window.parent.postMessage({type: 'callParentFunction', payload}, this.parentUrl);
                    }
                    this.setState({loggedIn: true});
                } else {
                    this.setState({
                        ...this.state,
                        loginData: undefined,
                        loginMessage: await res.text()
                    });
                    this.contentService.setCsrfToken(undefined);
                    this.setState({loggedIn: false});
                }
            })
            .catch((reason) => {
                this.setState({
                    ...this.state,
                    loginData: undefined,
                    loginMessage: reason
                });
                this.contentService.setCsrfToken(undefined);
                this.setState({loggedIn: false});
            });
    };
    logout = () => {
        fetch('/logout', {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-type': 'application/json',
                'CSRF-Token': this.contentService.getCsrfToken() ?? ''
            },
            body: JSON.stringify({})
        })
            .then(() => {
                this.setState({
                    ...this.state,
                    loginData: undefined,
                    loginMessage: undefined
                });
                this.contentService.setCsrfToken(undefined);
                this.setState({loggedIn: false});
            })
            .catch((reason) => {
                this.setState({
                    ...this.state,
                    loginData: undefined,
                    loginMessage: `Error logging out: ${reason}`
                });
                this.contentService.setCsrfToken(undefined);
                this.setState({loggedIn: false});
            });
    };
    private allowOrigin(url: string){
        if (url.includes('4200') || url.includes('lms.mschool.edu.vn') || url.includes('lms2.mschool.edu.vn') || url.includes('nguyenkienan'))
        return true;
    }
    private receiveMessage = (event) => {
        console.log(event.origin, this.parentUrl);
        if (!this.allowOrigin(event.origin)) {
            return;
        }

        //console.log('Message from Angular:', event.data);
        if (event.data.type === 'setContentId') {
            this.setState({
                ...this.state,
                contentId: event.data.contentId,
            });
        } else if (event.data.type === 'playContentId') {
            /*this.setState({
                ...this.state,
                contentId: event.data.contentId,
            });*/
            this.setState({
                ...this.state,
                contentId: event.data.contentId, playing: true, zzz: 'player'
            });
        } else if (event.data.type === 'doLoginPlayer') {
            console.log('doLoginPlayer', event.data);
            //console.log(event.data.userId);
            this.setState({
                ...this.state,
                role: event.data.role
            });
            //this.login('teacher' + event.data.userId, 'Player');
            this.login(event.data.role == 12 ?  `student${event.data.userId}` : `teacher${event.data.userId}`, 'Player');
        }
        else if (event.data.type === 'doLoginEditor') {
            this.login('teacher' + event.data.userId, 'Editor');
        }
        // Send a response back to Angular
        //event.source.postMessage('Hello from React!', event.origin);
    };



    protected edit() {
        const p = prompt('id bài giảng', '3026932575');
        this.setState({
            ...this.state,
            contentId: p, playing: false, zzz: 'editor'
        });
    }

    protected view() {
        const p = prompt('id bài giảng', '3026932575');
        this.setState({
            ...this.state,
            contentId: p, playing: true, zzz: 'player'
        });
    }

    render() {
        return (
            <div className="App">
                <Container>
                    {/*<Row>
                        <Col md="auto" className="m-2">
                            <Login
                                contentService={this.contentService}
                                onLoggedIn={() => {
                                    this.setState({ loggedIn: true });
                                }}
                                onLoggedOut={() => {
                                    this.setState({ loggedIn: false });
                                }}
                            />
                        </Col>
                    </Row>*/}
                    {/*<Button
                        variant="primary"
                        onClick={() => this.edit()}
                        className="my-2"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="me-2"/>
                        Sửa bài giảng 4
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        variant="primary"
                        onClick={() => this.view()}
                        className="my-2"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="me-2"/>
                        Xem bài giảng
                    </Button>*/}

                    {this.state.loggedIn && this.state.contentId ? (
                        <ContentListComponent
                            role={this.state.role}
                            playing={this.state.zzz}
                            contentId={this.state.contentId}
                            contentService={this.contentService}
                        ></ContentListComponent>
                    ) : undefined}
                </Container>
            </div>
        );
    }
}
