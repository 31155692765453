import React from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';

// The .js references are necessary for requireJs to work in the browser.
import {IContentService, IContentListEntry} from '../services/ContentService';
import ContentListEntryComponent from './ContentListEntryComponent';
import H5PEditor from './H5PEditor';
import H5PPlayer from './H5PPlayer';
import {Simulate} from "react-dom/test-utils";
import playing = Simulate.playing;
import Alert from "react-bootstrap/Alert";

export default class ContentList extends React.Component<{
    contentService: IContentService;
    contentId: any;
    playing: any;
    role: number
}> {
    constructor(props: { contentService: IContentService, contentId: any, playing: any, role: number }) {
        super(props);
        this.state = {contentList: []};
        this.contentService = props.contentService;
    }

    public state: {
        contentList: IContentListEntry[];
    };

    protected contentService: IContentService;
    /**
     * Keeps track of newly created content to assign a key
     * @memberof ContentList
     */
    protected newCounter = 0;
    public async componentDidMount(): Promise<void> {
        //this.contentType.contentId = '972927988';
        //await this.updateList();
    }

    public render(): React.ReactNode {
        const contentId = this.props.contentId;
        const contentType = {
            contentId: contentId,
            mainLibrary: '',
            title: 'New H5P',
            originalNewKey: `new-${this.newCounter++}`,
            role: this.props.role
        };
        return (
            <div>
                {/*<Button
                    variant="primary"
                    onClick={() => this.new()}
                    className="my-2"
                >
                    <FontAwesomeIcon icon={faPlusCircle} className="me-2"/>
                    Tạo mới bài giảng {this.props.playing}
                </Button>*/}
                <ListGroup>
                    {this.props.playing === "player" ? (
                        <H5PPlayer
                            contentService={this.contentService}
                            data={contentType}
                            key={contentType.originalNewKey ?? contentType.contentId}
                            onDiscard={() => this.onDiscard(contentType)}
                            onDelete={() => this.onDelete(contentType)}
                            onSaved={(newData) =>
                                this.onSaved(contentType, newData)
                            }
                            generateDownloadLink={
                                this.contentService.generateDownloadLink
                            }
                        ></H5PPlayer>
                    ) : (
                        <H5PEditor
                            contentService={this.contentService}
                            data={contentType}
                            key={contentType.originalNewKey ?? contentType.contentId}
                            onDiscard={() => this.onDiscard(contentType)}
                            onDelete={() => this.onDelete(contentType)}
                            onSaved={(newData) =>
                                this.onSaved(contentType, newData)
                            }
                            generateDownloadLink={
                                this.contentService.generateDownloadLink
                            }
                        ></H5PEditor>
                    )}

                    {/*{this.state.contentList.map((content) => (
                        <ContentListEntryComponent
                            contentService={this.contentService}
                            data={content}
                            key={content.originalNewKey ?? content.contentId}
                            onDiscard={() => this.onDiscard(content)}
                            onDelete={() => this.onDelete(content)}
                            onSaved={(newData) =>
                                this.onSaved(content, newData)
                            }
                            generateDownloadLink={
                                this.contentService.generateDownloadLink
                            }
                        ></ContentListEntryComponent>
                    ))}*/}
                </ListGroup>
            </div>
        );
    }

    protected async updateList(): Promise<void> {
        const contentList = await this.contentService.list();
        this.setState({contentList});
    }

    protected new() {
        this.setState({
            contentList: [
                {
                    contentId: 'new',
                    mainLibrary: undefined,
                    title: 'New H5P',
                    originalNewKey: `new-${this.newCounter++}`
                },
                ...this.state.contentList
            ]
        });

        /*const payload = {key: 'value', contentList: this.state.contentList}; // Thay thế bằng tham số thực tế của bạn
        const parentUrl = process.env.REACT_APP_PARENT_URL ?? '';
        window.parent.postMessage({type: 'callParentFunction', payload}, parentUrl);*/
    }

    protected onDiscard(content) {
        /*this.setState({
            contentList: this.state.contentList.filter((c) => c !== content)
        });*/
    }

    protected async onDelete(content: any) {
        if (!content.contentId) {
            return;
        }
        try {
            await this.contentService.delete(content.contentId);
            this.setState({
                contentList: this.state.contentList.filter((c) => c !== content)
            });
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message);
            }
        }
    }

    protected async onSaved(
        oldData: any,
        newData: any
    ) {
        /*this.setState({
            contentList: this.state.contentList.map((c) =>
                c === oldData ? newData : c
            )
        });*/
    }
}
